<template>
    <div class="p-main p-main--search container">
        <section>
            <h1 class="h5 text-center my-5">
                <span v-if="search_column=='dramatist'">作者が</span>
                <span v-if="search_column=='organizer'">劇団が</span>
                <span v-if="search_column=='director'">演出が</span>
                <b>「{{ query }}</b><span v-if="search_column=='date'">年</span>」の作品</h1>
        </section>
        <section v-if="errored">
            <p>ごめんなさい。エラーが起きました。</p>
        </section>
        <section v-else>
            <div v-if="loading">
                <Loading></Loading>
            </div>
            <div class="c-appeare my-3" v-bind:class="{'is-active': loading}">
                <div v-if="items" class="mt-2">
                    <p class="mb-3 h5 text-center">{{ record }} 件の作品が見つかりました。</p>
                    <div class="p-card__sort mb-5 text-right"><a @click="sort(0)">日付順</a>｜<a @click="sort(1)">作品名順</a></div>
                    <div class="c-card p-card p-card--index" v-for="(item, index) in getLists" :key="index">
                        <div class="c-card__sub p-card__sub">
                            <router-link :to="{name:'post', params: { keyword: item.id }}">
                                <span v-if="item.thumbnailpath">
                                    <img :src=item.thumbnailpath>
                                </span>
                                <span v-else><img src="https://louis-archive.s3-ap-northeast-1.amazonaws.com/assets/image/no-image.png"></span>
                            </router-link>
                        </div>
                        <div class="c-card__main p-card__main">
                            <PostTitle :id="item.id" :title="item.title" :subtitle="item.subtitle" :furigana="item.furigana" :date="item.date" :theater="item.theater" :dramatist="item.dramatist" :translator="item.translator" :organizer="item.organizer" :director="item.director"></PostTitle>
                        </div>
                    </div>
                    <nav class="my-5">
                        <paginate v-model="currentPage" :page-count="getPageCount" :initial-page="4" :page-range="3" :margin-pages="2" :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'" :container-class="'pagination justify-content-center'" :page-class="'page-item'" :prev-link-class="'page-link'" :next-link-class="'page-link'" :page-link-class="'page-link'">
                        </paginate>
                    </nav>
                </div>
                <div v-else>
                    <div class="mb-3 h5 text-center">作品が見つかりませんでした</div>
                </div>
            </div>
        </section>
        
    </div>
</template>
<script>
import axios from 'axios'
import moment from "moment";
import PostTitle from '../components/PostTitle'
import Loading from '../components/Loading'


export default {
    components: {
        PostTitle,
        Loading,
    },
    //API格納用のプロパティ
    data() {
        return {
            apiurl: 'https://script.google.com/macros/s/AKfycbxjHaI1WMlTAMvnncuNEoYi49xaKPNfVYbKUXdPuM1D8XF-_a-v3kbuzF9R1juBQ9wJ1w/exec',
            items: null,
            loading: true,
            errored: false,
            record: null,
            query: "すべて",
            search_column: null,
            parPage: 20,
            //currentPage: 1,
            currentPage: this.currentPage = this.$route.params.page || 1,
            thumbnailpath: null
        }
    },
    filters: {
        /**
         * @param {Date} value    - Date オブジェクト
         * @param {string} format - 変換したいフォーマット
         */
        moment(value, format) {
            return moment(value).format(format);
        }
    },
    created() {


    },
    mounted() {
        this.getData(this.$route.params.keyword);
    },

    methods: {

        getData: function(term) {//データ取得

            this.changeLoadingFlag();

            this.query = term;

            if (this.query != null) {
                this.url = this.apiurl + '?q=' + this.query;
            } else {
                this.query = "すべて"
            }

            console.log(this.url);

            this.search_column = this.$route.name;
            this.thumbnailpath = this.thumbnail

            
            axios
                .get(this.url)
                // .then(function (response) {
                //     // handle success(axiosの処理が成功した場合に処理させたいことを記述)
                //   console.log(response.data);
                // })
                .then(response => {

                    this.record = response.data.length;
                    if (this.record != 0) { //0件でなければ変数をセットする
                        this.items = response.data
                    }

                    console.log(this.items);
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },

        changeLoadingFlag: function() {//ローディング表示
            this.loading = true;

        },

        clickCallback: function(pageNum) {
            this.currentPage = Number(pageNum);
            // this.$router.push({
            //     name: 'page',
            //     params: {
            //         page: this.currentPage
            //     }
            // });
            this.$scrollTo('#app', 1000, { offset: -60 });
        },
        getUrlQueries: function() { // URLのクエリーを配列で返す。ex. {q: "a", search: "dramalist"}
            var queryStr = window.location.search.slice(1); // 文頭?を除外 
            var queries = {};

            // クエリがない場合は空のオブジェクトを返す
            if (!queryStr) {
                return queries;
            }

            // クエリ文字列を & で分割して処理
            queryStr.split('&').forEach(function(queryStr) {
                // = で分割してkey,valueをオブジェクトに格納
                var queryArr = queryStr.split('=');
                queries[queryArr[0]] = queryArr[1];
            });
            return queries;
        },

        sort(index) {
            switch (index) {
                case 0:
                    console.log("date")
                    this.items.sort(function(a, b) {
                        if (a.date < b.date) return -1;
                        if (a.date > b.date) return 1;
                        return 0;
                    });
                    break;
                case 1:
                    console.log("sort")
                    this.items.sort(function(a, b) {
                        if (a.furigana < b.furigana) return -1;
                        if (a.furigana > b.furigana) return 1;
                        return 0;
                    });
                    break;
                    // case 2:
                    //     this.sortItems.sort(function(a, b) {
                    //         if (a.age < b.age) return -1;
                    //         if (a.age > b.age) return 1;
                    //         return 0;
                    //     });
                    //     break;
                default:
            }
        }


    },
    computed: {
        // getItems: function() {
        //     let current = this.currentPage * this.parPage;
        //     let start = current - this.parPage;
        //     return this.items.slice(start, current);
        // },
        // getPageCount: function() {
        //     return Math.ceil(this.items.length / this.parPage);
        // }
        getItems: function() {
            return this.items;
        },
        getLists: function() {
            let current = this.currentPage * this.parPage;
            let start = current - this.parPage;
            return this.getItems.slice(start, current);
        },
        getPageCount: function() {
            return Math.ceil(this.getItems.length / this.parPage);
        }
    },
    watch: {
       '$route' (to) {
        console.log(to.params.keyword);
        this.getData(to.params.keyword);
       }
     },
}
</script>